<template>
    <div>
        <van-nav-bar title="反馈记录" left-text="" right-text="申请" left-arrow @click-left="url('/')"
            @click-right="url('/bug')" />
        <div class="top">
            <div class="title">
                <div class="title-text">反馈记录</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-cell v-for="(item,index) in list" :key="index" :url="'/bug_detail?id='+item.id"
                    :title="item.create_time" :label="item.content">
                    <template slot="default">
                        <van-tag type="primary" v-if="item.state==1">已回复</van-tag>
                        <van-tag type="danger" v-if="item.state==0">待回复</van-tag>
                    </template>
                </van-cell>
            </van-cell-group>
            <div v-if="list.length == 0">
                <van-empty description="列表为空" />
            </div>
        </div>

    </div>
</template>

<script>
    import {
        bug_list
    } from '@/api/bug.js';
    import {
        Notify,
    } from 'vant';
    export default {
        name: 'bug_list',
        data() {
            return {
                list: [],
            }
        },
        mounted() {
            this.get_list();
        },
        methods: {

            get_list() {
                bug_list({}).then(res => {
                    if (res.code == 1) {
                        this.list = res.data;
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .van-nav-bar__title,
    /deep/ .van-nav-bar__text {
        color: var(--main-color) !important;
    }

    /deep/ .van-nav-bar__title {
        color: var(--main-color);
        font-family: 'FZCS';
    }

    /deep/ .van-nav-bar__left i {
        color: var(--main-color) !important;
    }
</style>